$rifCity24Color: rgb(177, 58, 142);

.rif-city-24 {

  .event-head__date {
    color: $rifCity24Color;
  }

  .header .menu-toggle {
    background-color: $rifCity24Color;
  }

  .sEventReg {
    background-color: $rifCity24Color;
  }

  .btn-primary {
    --bs-btn-color: #000;
    --bs-btn-bg: $rifCity24Color;
    --bs-btn-border-color: $rifCity24Color;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: $rifCity24Color;
    --bs-btn-hover-border-color: $rifCity24Color;
    --bs-btn-focus-shadow-rgb: 0, 145, 145;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: $rifCity24Color;
    --bs-btn-active-border-color: $rifCity24Color;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: $rifCity24Color;
    --bs-btn-disabled-border-color: $rifCity24Color;
    background-color: $rifCity24Color;
  }

  .form-wrap__add-new:before {
    background-color: $rifCity24Color;
  }
}

